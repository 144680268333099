import type { OnProgress } from ".";
import { Subscriptions } from "../resources";
import type { LedgerEntity, LedgerListParams } from "../types";

export async function getAllLedgerData(
  params: LedgerListParams = {},
  tokenGetter: () => Promise<string>,
  onProgress?: OnProgress,
) {
  const allLedgerData: LedgerEntity[] = [];
  const limit = 1000;
  let offset = 0;

  while (true) {
    const ledgerData = await Subscriptions.getLedgerData(
      {
        ...params,
        limit: limit,
        offset: offset,
      },
      await tokenGetter(),
    );

    const total = ledgerData.meta.total;
    onProgress?.(Math.min(1, (offset + limit) / total));
    allLedgerData.push(...ledgerData.data);

    if (offset + limit >= total) {
      break;
    }

    offset += limit;
  }

  return allLedgerData;
}
